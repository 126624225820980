<template>
    <div class='card' v-if='analysts.length > 0'>
        <div class='header-title'>
            <span class='title-text'>{{titleText}}</span>
        </div>
        <LoadingSpinner :showLoading="loading" style="width: 100%;height:100%"/>
        <template v-if="!loading">
            <infinite-scroll @infinite-scroll="loadMoreResults" message="" :noResult="noMoreResults" :style='scrollStyle' v-if="type === WHO_TO_FOLLOW_TYPES['GROUP_PROFILE']">
                <div class='who-to-follow-content'>
                    <WhoToFollowListItem v-for="analyst in analysts" :key="analyst.analystId" :followee="analyst" @follow-analyst="follow" @unfollow-analyst="unfollow"/>
                </div>
            </infinite-scroll>
            <div class='who-to-follow-content' v-else-if="type === WHO_TO_FOLLOW_TYPES['HOME_DISCOVER']">
                <WhoToFollowListItem v-for="analyst in analysts" :key="analyst.analystId" :followee="analyst" @follow-analyst="follow" @unfollow-analyst="unfollow"/>
            </div>
        </template>
    </div>
</template>

<script>
import InfiniteScroll from '../../common/scroll/infinite-scroll-vue3.vue';
import LoadingSpinner from '../../common/LoadingSpinner';
import WhoToFollowListItem from './WhoToFollowListItem.vue';

import FeedService from '../../../service/FeedService';
import GroupService from '../../../service/GroupService';
import UserUtils from '../../../utilities/UserUtils';

import {WHO_TO_FOLLOW_TYPES} from '../../../common/constants';

import EventBus from '../../../event-bus';
import { mapState } from 'vuex';

export default {
    name: 'WhoToFollowList',
    components: {
        LoadingSpinner,
        InfiniteScroll,
        WhoToFollowListItem
    },
    props: {
        type: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            //CONSTANTS
            WHO_TO_FOLLOW_TYPES,

            analysts: [],
            loading: false,
            noMoreResults: false,
            scrollStyle: "width: 100%; max-height: 100%; height: 500px;"
        };
    },
    computed: {
        ...mapState(['selectedGroup']),

        titleText() {
            const TYPE_HEADER_MAP = {
                'HomeDiscover': 'Investors to Follow',
                'GroupProfile': 'Members to Follow'
            }

            return TYPE_HEADER_MAP[this.type];
        }
    },

    created() {
        EventBus.on('followed-changed', this.loadAnalysts);
    },
    mounted() {
        this.loadAnalysts();
    },

    methods: {
        loadAnalysts() {
            this.loading = true;
            
            if (this.type === WHO_TO_FOLLOW_TYPES['HOME_DISCOVER']) {
                FeedService.getAnalystToFollow().then((resp) => {
                    const arr = resp.data.data;

                    this.analysts = arr.filter((el) => {
                        return !el.activityStats.following && el.userTypeId !== 2;
                    }).slice(0,5);

                    this.loading = false;
                });
            } else if (this.type === WHO_TO_FOLLOW_TYPES['GROUP_PROFILE']) {
                this.fetchGroupMembersToFollow(10, 0);
            }
        },

        async loadMoreResults() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreResults) {
                    this.fetchGroupMembersToFollow(10, this.analysts.length);
                } else {
                    this.loading = false;
                }
            }
        },

        fetchGroupMembersToFollow(limit, offset) {
            GroupService.getMembersToFollow(this.selectedGroup.groupId, offset, limit).then( resp => {
                this.loading = false;

                let members = resp.data.members;

                if (members.length > 0) {
                    members = members.reduce((total, member) => {

                        if (!UserUtils.isUser(member.userId)) {
                            const analyst = member.analyst;

                            const obj = {
                                firstName: analyst.firstName ? analyst.firstName : null,
                                middleName: analyst.middleName ? analyst.middleName : null,
                                lastName: analyst.lastName ? analyst.lastName: null,
                                name: analyst.name,
                                bio: analyst.bio,
                                avatar: analyst.avatar,
                                activityStats: analyst.activityStats,
                                analystId: analyst.analystId
                            }
                        
                            total.push(obj);
                        }

                        return total;
                }, [])

                    const results = this.analysts.concat(members);

                    this.analysts = results;

                    this.noMoreResults = false;
                } else {
                    this.noMoreResults = true;
                }
            })
        },
        

        async follow(analyst) {
            this.loading = true;
            await FeedService.followUser(analyst.name).then((resp) => {
                
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are now following ' + analyst.name, detail: '', life: 2000, group: 'tc' });
                    this.$store.state.users.user.analyst.followed.followedAnalysts[analyst.analystId] = analyst;
                    
                    EventBus.emit('followed-changed');
                    this.loading = false;
                }
            });
        },

        async unfollow(analyst) {
            this.loading = true;
            await FeedService.unfollowUser(analyst.name).then((resp) => {
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'success', summary: 'You are no longer following ' + analyst.name, detail: '', life: 2000, group: 'tc' });
                    delete this.$store.state.users.user.analyst.followed.followedAnalysts[analyst.analystId];
                   
                    EventBus.emit('followed-changed');
                    this.loading = false;
                }
            });
        },
    }
};
</script>

<style scoped>
*:not(.pi), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.card {
    padding: 16px 0px;
}

.header-title {
    border-bottom: 1px solid #BFBFBF;
    width: 100%;
}
.title-text {
    display: inline-block;
    color: #32364e;
    font-weight: bold;
    font-size: 24px;
    margin: 0px 24px;
    padding-bottom: 16px;
}

.who-to-follow-content {
    display: flex;
    flex-direction: column;
}
</style>