<template>
    <div class='analyst-to-follow-block'>
      <div class='analyst-first-row'>
        <div class='analyst-first-column'>
            <UserAvatar :imageSrc="followee.avatar" customStyle="width: 36px; height: 36px;" @click='postUtils.goToAnalystProfile(followee.name, $event)'/>
        </div>
        <div class='analyst-second-column'>
            <div class='block-top-row'>
                <div class='names-container' @click='postUtils.goToAnalystProfile(followee.name, $event)'>
                    <span class='full-name' v-if='stringUtils.fullName(followee) !== null'>{{stringUtils.fullName(followee)}}</span>
                    <span class='followers-posts-label'>{{followersText}} | {{postsText}}</span>
                </div>
                <Button :class="{'follow-button': true, 'following': followee.activityStats.following }" @click="toggleFollow(followee)" :label="followee.activityStats.following ? 'Unfollow' : 'Follow'" />
            </div>
        </div>
      </div>
      <div class='analyst-second-row' v-if='followee.bio'>
          <span class='bio'>{{followee.bio}}</span>
      </div>
    </div>
</template>

<script>

import UserAvatar from '../../profile/UserAvatar.vue';

import PostUtils from '../../../utilities/PostUtils';
import StringUtils from '../../../utilities/StringUtils';

export default {
    name: 'WhoToFollowListItem',
    emits: ['follow-analyst', 'unfollow-analyst'],
    props: {
        followee: {
            type: Object,
            required: true
        }
    },
    components: {
        UserAvatar
    },

    data() {
        return {
            postUtils: PostUtils,
            stringUtils: StringUtils
        }
    },

    computed: {
        followersText() {
            return (this.followee.activityStats.numFollowers ? this.followee.activityStats.numFollowers : 0) +
              (this.followee.activityStats.numFollowers > 1 ? ' Followers' : ' Follower');
        },

        postsText() {
            return (this.followee.activityStats.numPosts ? this.followee.activityStats.numPosts : 0) +
              (this.followee.activityStats.numPosts > 1 ? ' Posts' : ' Post');
        }
    },  

    methods: {
        toggleFollow(analyst) {
            if (analyst.activityStats.following) {
                this.$emit('unfollow-analyst', analyst);
            } else {
                this.$emit('follow-analyst', analyst);
            }
        },
    }
}
</script>

<style scoped>
.analyst-to-follow-block {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
.analyst-to-follow-block:last-child {
    padding-bottom: 0px;
    border-bottom: none;
}

.analyst-first-row {
    display: flex;
}
.analyst-second-row {
    margin-top: 8px;
}

[class*='column'] {
    display: flex;
    flex-direction: column;
}
.analyst-first-column {
    align-items: center;
    margin-right: 16px;
}
.p-avatar:hover {
    cursor: pointer;
}
.followers-posts-label {
    font-size: 12px;
    color: #BFBFBF;
}

.analyst-second-column {
    width: 100%;
}
.block-top-row {
    display: flex;
}
.names-container:hover {
    cursor: pointer;
}
.full-name {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #32364e;
}
.username {
    display: block;
    font-size: 12px;
    color: #BFBFBF;
}
::v-deep(.follow-button) {
    padding: 8px 16px;
    margin-left: auto;
    background: #FFFFFF;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    color: #32364e;
}
::v-deep(.follow-button:hover) {
    background: #33CC99;
    border-color: #33CC99;
    color: #FFFFFF;
}
::v-deep(.follow-button:focus) {
    border-color: #33CC99;
}
::v-deep(.follow-button.following) {
    background: #33CC99;
    color: #FFFFFF;
}

.bio {
    color: black;
}
</style>